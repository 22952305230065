import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"

function MobileMenu({ setOpen }) {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <Menu
      isOpen={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      burgerButtonClassName="md:hidden block"
      right
    >
      <a href="#">HOME</a>
      <a href="#about">ABOUT</a>
      <a href="#services">SERVICES</a>
      <a href="https://marcmed.isolvedhire.com">CAREERS</a>
      <a href="#contact">CONTACT</a>
      <a className="cta bg-grey text-white my-2" role="button">
        CLIENT RESOURCES
      </a>
      <a href="https://marc.repay.io/ebpp/#/" className="cta bg-red text-white" role="button">
        MAKE PAYMENT
      </a>
    </Menu>
  )
}

export default MobileMenu
