import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import MobileMenu from "./MobileMenu"
import ComingSoonModal from "./ComingSoonModal"

function Header() {
  const [open, setOpen] = useState(false)

  return (
    <header className="relative h-[176px] md:h-auto">
      <MobileMenu setOpen={setOpen} />

      <div className="flex justify-between p-5 font-open-sans md:container mx-auto  pt-20 md:pt-6 md:relative fixed w-full bg-white z-10">
        <div>
          <StaticImage
            className="w-full sm:max-w-full max-w-[200px]"
            objectFit="contain"
            src="../images/Marc Logo Final.png"
          />
        </div>
        <div className="md:flex flex-col justify-between items-end">
          <div className="hidden md:flex space-x-10">
            <p class="m-0">
              <span className="text-red">Phone</span> :{" "}
              <a href="tel:8338560238">833-856-0238</a>
            </p>
            <p class="m-0">
              <span className="text-red">Email</span> :{" "}
              <a href="mailto:info@marcmed.com">info@marcmed.com</a>
            </p>
          </div>
          <div className="flex xl:space-x-6 font-bold xl:flex-row flex-col xl:m-0 md:mt-6 absolute w-full left-0 top-0 m-0 items-center md:relative md:w-auto">
            <nav>
              <ul className="hidden md:flex space-x-6 list-none m-0  justify-between">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <a href="#about">ABOUT</a>
                </li>
                <li>
                  <a href="#services">SERVICES</a>
                </li>
                <li>
                  <a href="https://marcmed.isolvedhire.com/">CAREERS</a>
                </li>
                <li>
                  <a href="#contact">CONTACT</a>
                </li>
              </ul>
            </nav>
            <ul className="flex space-x-2 list-none xl:m-0 mt-6 ml-0">
              <li>
                <a
                  className="cta bg-grey text-white"
                  role="button"
                  onClick={() => setOpen(true)}
                >
                  CLIENT RESOURCES
                </a>
              </li>
              <li>
                <a href="https://marc.repay.io/ebpp/#/" className="cta bg-red text-white">
                  MAKE PAYMENT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ComingSoonModal open={open} setOpen={setOpen} />
    </header>
  )
}

export default Header
