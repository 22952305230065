import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ComingSoonModal from "./ComingSoonModal"

function Footer() {
  const [open, setOpen] = useState(false)

  return (
    <footer className="bg-dark-grey py-16 px-5">
      <div className="container mx-auto">
        <div className="flex sm:flex-row flex-col sm:justify-between justify-start sm:space-y-0 ">
          <div>
            <StaticImage
              objectFit="contain"
              className="mr-12"
              src="../images/logo-footer.png"
            />
            <p className="text-white text-sm max-w-sm">
              Providing professional medical office administration and
              life-cycle accounts receivable management resulting in a fast
              track to revenue realization.
            </p>
          </div>
          <div>
            <div className="footer-contact gap-6 grid lg:grid-cols-3 grid-cols-1 font-lora content-center">
              <div className="items-center">
                <p className="m-0 text-red">
                  <i>Call Us Now</i>
                </p>
                <a
                  className="text-xl font-bold text-white"
                  href="tel:8338560238"
                >
                  833-856-0238
                </a>
              </div>
              <div>
                <p className="m-0 text-red">
                  <i>Send Us Message</i>
                </p>
                <a
                  className="text-xl font-bold text-white"
                  href="mailto:info@marcmed.com"
                >
                  info@marcmed.com
                </a>
              </div>
              <div className="lg:mr-6">
                <p className="m-0 text-red">
                  <i>Our Address</i>
                </p>
                <p className="text-xl font-bold text-white m-0">
                  PO Box 401418 <br />
                  Las Vegas, NV 89140
                </p>
              </div>
            </div>
            <div className="flex mt-6 flex-wrap items-end lg:justify-end  justify-start">
              <div>
                <p className="font-bold text-white hours lg:ml-0 ml-6 relative">
                  Business Hours
                </p>
                <p className="text-[#bfbfbf]">
                  Monday - Friday : 8:00am to 5:00pm
                  <br />
                  Closed Saturday & Sunday
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row md:space-y-0 space-y-4 flex-col md:items-center items-start justify-between mt-16 pt-6 border-t border-solid border-grey">
          <p className="m-0">
            <small className="text-sm">
              MARC © {new Date().getFullYear()}. All Rights Reserved.
            </small>
          </p>
          <ul className="flex md:flex-row flex-col md:space-x-6 md:space-y-0 space-y-4 list-none m-0 text-sm">
            <li>
              <a href="">HOME</a>
            </li>
            <li>
              <a href="">ABOUT</a>
            </li>
            <li>
              <a href="">SERVICES</a>
            </li>
            <li>
              <a href="">CONTACT</a>
            </li>
            <li>
              <a
                role="button"
                className="text-white font-bold"
                onClick={() => setOpen(true)}
              >
                CLIENT RESOURCES
              </a>
            </li>
            <li>
              <a href="https://marc.repay.io/ebpp/#/" className="text-red font-bold">
                MAKE PAYMENT
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ComingSoonModal open={open} setOpen={setOpen} />
    </footer>
  )
}

export default Footer
